import React from "react";

import { Link } from "react-router-dom";

import { Image, Row } from "react-bootstrap";
import CustomText from "../../components/CustomText";
import Colors from "../../constants/Colors";

function StoreSelection() {
  console.log("Render StoreSelection");

  return (
    <Row className="ms-3 me-3 mt-5 mb-4">
      <Row className="mt-2 text-center justify-content-center">
        <Image
          style={{ height: 200, width: 230 }}
          src={process.env.PUBLIC_URL + "/logo192.png"}
        />
        <Row className="mt-3 text-center">
          <CustomText color={Colors.primary} size={26} bold>
            DIAGME.ONLINE
          </CustomText>
        </Row>
      </Row>
      <Row className="mt-5" />
      <Link to="https://play.google.com/store/apps/details?id=online.diagme">
        <Row className="mt-5 justify-content-center">
          <Image
            style={{ height: 65, width: 240 }}
            src={process.env.PUBLIC_URL + "/google.png"}
          />
        </Row>
      </Link>
      <Link to="https://apps.apple.com/us/app/diagme/id6469189916">
        <Row className="mt-3 justify-content-center">
          <Image
            style={{ height: 65, width: 240 }}
            src={process.env.PUBLIC_URL + "/apple.png"}
          />
        </Row>
      </Link>
    </Row>
  );
}

export default StoreSelection;
